import { CrxTokenRes } from "./../../types/CrxTokenRes";
import axios from "axios";
import moment from "moment";
import StorageService from "../StorageService";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_CRONOEX_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

function isTokenExpired(expire_at: number): boolean {
  return moment.unix(expire_at).isBefore(moment());
}

function parseTokenRes(tokenRes: CrxTokenRes): string {
  const token = tokenRes.token.split(" ");
  return token[1];
}

const getToken = async () => {
  const localToken = await StorageService.getItem("crx_token");
  if (localToken) {
    const localTokenJson: CrxTokenRes = JSON.parse(localToken);
    if (!isTokenExpired(localTokenJson.expires_at)) {
      return parseTokenRes(localTokenJson);
    } else {
      await StorageService.removeItem("crx_token");
    }
  }

  const token = await api
    .post("/auth", {
      user: process.env.REACT_APP_API_CRONOEX_USER,
      pass: process.env.REACT_APP_API_CRONOEX_PASS,
    })
    .then(async (res) => {
      await StorageService.setItem("crx_token", JSON.stringify(res.data));
      const tokenResApi: CrxTokenRes = res.data;

      return parseTokenRes(tokenResApi);
    })
    .catch((err) => {
      console.error("Erro ao  obter token.", err);
      alert("Não foi possível obter um token de autorização com a api crx.");
    });

  return token;
};

export default getToken;
