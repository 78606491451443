import React, { } from 'react';

const CadastroLnHelper = (props: any) => {

    return (

        <>
            <p className='text-center'>Ainda não é <b>Cliente Leve Natural</b><br />
                (ou quer atualizar o seu cadastro)?</p>
            <div className='d-flex justify-content-center'>
                <a href="https://sejalevenatural.com.br/v1/apps/main#cadastro" target="_blank" className='btn btn-success' style={{ padding: '3px 30px' }}>CLIQUE AQUI</a>
            </div>
        </>

    )
}


export default CadastroLnHelper