import React, { useEffect, useState } from 'react';
import { Button, Form, Input } from 'reactstrap';
import { ErrorOption, useForm } from 'react-hook-form';
import { cpf as cpfValidator } from 'cpf-cnpj-validator';
import './styles.scss'
import { useHistory } from "react-router-dom";
import usePageTracking from '../../hooks/usePageTracking';
import { padStart } from 'lodash';
import LoginService from '../../services/LoginService';
import CadastroLnHelper from './cadastro-ln-helper';

const Login = (props: any) => {
    usePageTracking();
    const { register, handleSubmit, setValue, formState: { errors }, setError, clearErrors, watch } = useForm();
    const [ramo1Width, setRamo1Width] = useState(0);
    const [ramo3Width, setRamo3Width] = useState(0);

    const watchCpf = watch('Cpf');

    let history = useHistory();

    const getCpfCompleto = (cpf: string): string => {
        const cpfCompleto: string = cpf && cpf.length > 0 ? padStart(cpf, 11, '0') : '';
        return cpfCompleto;
    }

    const handlePosLogin = () => {
        history.push('/promocoes');
    }

    const validarForm = () => {
        const cpfCompleto = getCpfCompleto(watchCpf);

        const isCpfValido = cpfValidator.isValid(cpfCompleto);

        let errorOptions: ErrorOption = {
            message: ''
        };

        if (cpfCompleto.length === 11) {

            errorOptions.message = 'CPF inválido';

            if (!isCpfValido) {

                if (watchCpf.length === 11) {
                    setError('Cpf', errorOptions);
                }

                return false;
            } else {
                return true;
            }
        } else {
            errorOptions.message = 'CPF incompleto';
            setError('Cpf', errorOptions);
        }

        return false;
    }

    const onSubmit = async (data: any) => {
        const isValido = validarForm();
        if (!isValido) {
            return false;
        } else {
            clearErrors();
        }

        try{
            LoginService.doLoginByCpf(watchCpf);
            handlePosLogin();
        }catch(e){
            console.log(e);
        }
    };

    const { REACT_APP_DEFAULT_LOGIN } = process.env;

    useEffect(() => {
        if (REACT_APP_DEFAULT_LOGIN) {
            setValue('Cpf', REACT_APP_DEFAULT_LOGIN);
        }
    }, [REACT_APP_DEFAULT_LOGIN, setValue]);

    useEffect(() => {
        let timeoutResize: any;

        function handleResize() {
            if (timeoutResize) {
                clearTimeout(timeoutResize);
            }
            timeoutResize = setTimeout(() => {
                let p = 22;
                if (window.innerWidth < 992) {
                    p = 30;
                }
                if (window.innerWidth < 768) {
                    p = 40;
                }
                setRamo1Width(window.innerWidth * p / 100);
            }, 250)
        }

        window.addEventListener('resize', handleResize);

        handleResize();
    })

    useEffect(() => {
        let timeoutResize: any;

        function handleResize() {
            if (timeoutResize) {
                clearTimeout(timeoutResize);
            }
            timeoutResize = setTimeout(() => {
                let p = 25;
                if (window.innerWidth < 992) {
                    p = 35;
                }
                if (window.innerWidth < 768) {
                    p = 40;
                }
                setRamo3Width(window.innerWidth * p / 100);
            }, 250)
        }

        window.addEventListener('resize', handleResize);

        handleResize();
    })

    return (

        <>
            <div className="d-flex py-5 container ">

                <div className="row">
                    
                    <div className="col-lg-6">
                        <div className="row">
                            <div className="col-lg-10 offset-lg-2">
                                <img src="/assets/images/banner-boas-vindas.webp" id="banner-boas-vindas" className='mt-5' />
                                <div className="py-3">
                                    <p style={{ width: '80%' }}>
                                        Olá <b>Cliente Leve Natural. Confira aqui as suas ofertas personalizads.</b><br />
                                        Basta digitar seu CPF (apenas números)
                                    </p>

                                    <Form onSubmit={handleSubmit(onSubmit)}>
                                        <div className="input-group mb-2 cpf" >
                                            <Input placeholder="CPF" innerRef={register} name="Cpf" type='text' maxLength={11} />
                                            <div className="input-group-append">
                                                <Button color="success">
                                                    CONTINUAR
                                                </Button>
                                            </div>
                                        </div>

                                        {errors.Cpf && <div className="text-danger text-center">{errors.Cpf.message}</div>}

                                    </Form>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                    <div className="col-lg-2 flex-column">
                        <img src="/assets/images/enfeite-3.webp" id="enfeite3" className='d-none d-lg-block' />
                        <img src="/assets/images/enfeite-1.webp" id="enfeite1" className='d-sm-block d-lg-none' />
                    </div>
                    <div className="col-lg-4 d-flex flex-column" >
                        <div>
                            <img src='/assets/images/arvore.webp' id='banner-arvore' className='mt-5'/>
                        </div>
                        <div className='d-none d-lg-block'>
                            <CadastroLnHelper />
                        </div>
                    </div>
                </div>
                
            </div>
            <div>
                <img src="assets/images/ramo-1.webp" id="ramo1" width={ramo1Width} className='d-none d-lg-block' />
                <img src="assets/images/ramo-3.webp" id="ramo3" width={ramo3Width} className='d-sm-block d-lg-none' />
            </div>
            <div className='d-lg-none d-xl-none pb-5 mb-5'>
                <CadastroLnHelper />
            </div>
        </>

    )
}


export default Login