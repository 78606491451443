import React from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

const VitrineCarousel = (props: any) => {

    const images: any = props.images;

    return (
        <div className="vitrine-container mx-auto d-block mb-2">
            <Carousel interval={3000} showThumbs={false} showIndicators={images.length > 1 ? true : false} showStatus={false} swipeable={true} emulateTouch={true} infiniteLoop={true} autoPlay={true}>
                {images.map((imgUrl: any) => {
                    return (
                        <div key={imgUrl} className="img-container" >
                            <div className='img' style={{ backgroundImage: `url(${imgUrl})` }}></div>
                        </div>
                    )
                })}
            </Carousel>
        </div>
    );
}

export default VitrineCarousel;