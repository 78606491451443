import Swal from 'sweetalert2';

class LoadingService {

    public static show(msg: string, title: string = '') {
        Swal.fire({
            title: title,
            text: msg,
            imageWidth: 60,
            imageHeight: 60,
            imageUrl: "/assets/images/loading_HNT_100x100.gif",
            showConfirmButton: false,
            backdrop: true,
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
        });
    }

    public static hide() {
        Swal.close();
    }
}

export default LoadingService;