import React from 'react';
import {
    Navbar,
    NavbarBrand,
} from 'reactstrap';
import SubHeader from './sub-header';
import './styles.scss';

const Header = () => {

    return (
        <>
            <Navbar color="primary" light expand="md" className='justify-content-between'>
                <NavbarBrand href='/login' className='mr-2 ml-0'>
                    <img src="/assets/images/logo-branco.webp" alt="Logo" id="logo-hortifruti"></img>
                    <img src="/assets/images/logo-natural-terra-branco.webp" alt="Logo" id="logo-natural-terra" ></img>
                </NavbarBrand>
                <NavbarBrand href='/login' className='mr-0 ml-2' id="logo-ln-container">
                    <img src="/assets/images/logo-leve-natural.webp" alt="Logo Natural da Terra" height={200} ></img>
                </NavbarBrand>
            </Navbar>
        </>
    )
}

export default Header;