import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import Layout from '../components/Layout';

const PrivateRoute = ({ component: Component, ...rest }) => {
    const [isAutenticado, setIsAutenticado] = useState(undefined);
    const { authState } = useSelector((state) => state);

    useEffect(() => {
        setIsAutenticado(authState.data.Cpf ? true : false);
    }, [authState.data.Cpf]);

    return (
        <Route {...rest} render={props => {

            return (
                <Layout {...props}>
                    {isAutenticado === true && <Component {...props} />}
                    {isAutenticado === false && <Redirect to={{ pathname: '/login', state: { from: props.location } }} />}
                </Layout>
            )
        }
        }></Route >
    )

}
export default PrivateRoute;