import axios from "axios";
import AlertService from "../AlertService";
import getToken from "./ApiCronoexAuth";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_CRONOEX_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

api.interceptors.request.use(
  async (config) => {
    if (config.url?.indexOf("/auth") === -1) {
      const token = await getToken();
      config.headers.Authorization = "Bearer " + token;
    }

    return Promise.resolve(config);
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const response = error.response ? error.response : { status: -1 };
    let msg = "";
    switch (response.status) {
      case -1:
        //A requisição foi cancelada..
        break;
      case 500:
        msg =
          "Alguma coisa deu errado. Por favor, tente novamente em alguns instantes.";
        await AlertService.error(msg, "Oops!");
        break;
      default:
        msg = "Erro " + response.status + ": " + response.statusText;
        await AlertService.error(msg, "Erro ao processar requisição!");
        break;
    }
    return Promise.reject(error);
  }
);

export default api;
