import { NONAME } from 'dns';
import React, { MouseEventHandler, useEffect, useState } from 'react';

const ButtonHnt = (props: PropsBtnHnt) => {
    const [imgUrl, setImgUrl] = useState('');

    const { icon, text, onClick } = props;

    useEffect(() => {
        setImgUrl('/assets/images/btn-icon-' + icon + '.png');
    }, [icon]);

    return (
        <button type="button" onClick={onClick} style={styleButton}>
            <img src={imgUrl} style={styleImg} />
            {text}
        </button>
    )
}
type PropsBtnHnt = {
    icon: 'left' | 'up' | 'smile',
    text: string,
    onClick?: MouseEventHandler<HTMLButtonElement> | undefined
};

const styleButton = {
    background: 'transparent',
    border: 'none',
    fontWeight: 'bold',
    color: '#534531',
};

const styleImg = {
    height: '30px',
    marginRight: '10px',
}

export default ButtonHnt;