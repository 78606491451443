import { store } from '../store';
import { authUpdate, userLogout } from '../actions';
import Auth from "../types/Auth";

class LoginService {

    static async doLoginByCpf(cpf: string) {
        const auth: Auth = {
            Cpf: cpf
        };
        store.dispatch(authUpdate(auth));
    }

    static async doLogout() {
        store.dispatch(userLogout());
    }
}

export default LoginService;