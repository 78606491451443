import moment from 'moment';
import React, { useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';
import { useSelector } from 'react-redux';
import CpfService from '../../services/CpfService';
import { PromotionItem } from '../../types/PromotionItem';
import './ModeloImpressao.scss';
interface Props {
    ref: HTMLElement
    promocoes: PromotionItem[]
}

export const ModeloImpressao = React.forwardRef<HTMLInputElement, Props>(
    (props, ref) => {

        const { authState } = useSelector((state: any) => state);

        useEffect(() => {
            setCpfMascarado(CpfService.mascarar(CpfService.formatar(authState.data.Cpf)));
        }, [authState.data.Cpf]);

        const [cpfMascarado, setCpfMascarado] = useState('');

        return (
            <div ref={ref} className='p-3' id="modelo-impressao">
                <h3 className='text-center'>Leve Natural – Ofertas Personalizadas</h3>
                <p className='text-center'>
                    Confira o que  separamos especialmente para você! Aproveite!!<br />
                    <small>*Ofertas válidas para o portador do CPF {cpfMascarado} até {moment(props.promocoes[0].promotion.endDate).format('DD/MM/YYYY')}</small>
                </p>
                <table className='mx-auto tabe'>
                    <thead>
                        <tr>
                            <th>Descrição</th>
                            <th className='text-right'>Valor</th>
                        </tr>
                    </thead>
                    <tbody>
                        {props.promocoes.map(item =>
                            <tr key={item.promotionId}>
                                <td>{item.promotion.description}</td>
                                <td className='text-right'><NumberFormat value={item.promotion.finalPrice} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} fixedDecimalScale={true} decimalScale={2} prefix={'R$'} /></td>
                            </tr>)
                        }
                    </tbody>
                </table>
            </div>
        );
    }
);
export default ModeloImpressao;