class CpfService {
    static formatar(cpf: string): string {
        if (!cpf) {
            return '';
        }

        //retira os caracteres indesejados...
        cpf = cpf.replace(/[^\d]/g, "");

        //realizar a formatação...
        return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
    }


    static mascarar(cpfFormatado: string): string {
        const splited = cpfFormatado.split('').map((str, ix) => {

            if (ix > 3 && ix < 11 && ['.', '-'].indexOf(str) === -1) {
                str = '*';
            }

            return str;
        });

        return splited.join('');
    }
}

export default CpfService;