import { PromotionItem } from '../../types/PromotionItem';
import './PromocaoItem.scss';
import { Button } from 'reactstrap';
import PromocaoService from '../../services/PromocaoService';
import CpfService from '../../services/CpfService';
import moment from 'moment';
import AlertService from '../../services/AlertService';
import LoadingService from '../../services/LoadingService';

const PromocaoItemSpendAndGet = (props: PromocaoItemProps) => {
    const { item } = props;

    const ativarPromocao = async (item: PromotionItem) => {
        LoadingService.show('Ativando a promoção...');
        const cpf = CpfService.formatar(item.customerId.substring(item.customerId.length - 11));

        const res = await PromocaoService.ativarPromocao(cpf, item.promotionId);

        LoadingService.hide();


        if (res) {
            await props.carregarPromocoes();
            AlertService.show('Promoção ativada com sucesso!');

        }
    }

    return (
        <div className="card promocao-item m-2">
            <img src="/assets/images/img-cupom.png" className='mb-2' />
            <div className="card-body d-flex flex-column justify-content-between">
                <div>
                    <h5 className="card-title">{item.promotion.description}</h5>
                    <p className="card-text">
                        {item.promotion.legalText}
                        <span>Válido até <b>{moment(item.promotion.endDate).format('DD/MM')}</b></span>
                    </p>
                </div>
                {item.promotion.requiresActivation && !item.activated &&
                    <Button color='primary' className='mt-2' block={true} onClick={() => ativarPromocao(item)}>Ativar Promoção</Button>
                }
            </div>
        </div >
    )
}

interface PromocaoItemProps {
    item: PromotionItem,
    carregarPromocoes: CallableFunction
}

export default PromocaoItemSpendAndGet;