import React from 'react';

const Err404 = (props:any) => {

    return (
        <div className="container my-3">
            <h2>Erro 404 - Página não encontrada</h2>
            <a href="/">Ir para a página inicial</a>
        </div>
    )
}


export default Err404;