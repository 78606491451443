import React from "react";
import { BrowserRouter, Redirect, Switch } from "react-router-dom";
import Login from "../components/Login";

import Layout from "../components/Layout";
import PrivateRoute from "./private-route";
import RouteWrapper from "./router-wrapper";
import Err404 from "../components/Errors/Err404";
import Promocoes from "../components/Promocoes";

const Routes = () => {
  return (
    <>
      <Switch>
        <PrivateRoute
          exact
          path="/"
          layout={Layout}
          component={() => <Redirect to="/promocoes"></Redirect>}
        />
        <RouteWrapper exact path="/login" layout={Layout} component={Login} />
        <PrivateRoute path="/promocoes" layout={Layout} component={Promocoes} />
        <RouteWrapper path="*" layout={Layout} component={Err404} />
      </Switch>
    </>
  );
};

export default Routes;
