
import { combineReducers } from 'redux';
import { clienteReducer } from './clienteReducer';
import { authReducer } from './authReducer';
import * as ACTIONS from '../actions/actionTypes';

const appReducer = combineReducers({
    'clienteState': clienteReducer,
    'authState': authReducer,
});

const initialState = appReducer({}, {})

const rootReducer = (state, action) => {

    if (action.type === ACTIONS.USER_LOGOUT) {
        state = initialState
    }

    return appReducer(state, action)
}

export default rootReducer