import React from 'react';
import Header from './Header';

const Layout = ({children}) => (
    <>
      <Header></Header>  
        <div className="children-container">
          {children}
        </div>
    </>
)

export default Layout;
