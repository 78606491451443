import *  as ACTIONS  from '../actions/actionTypes';

const initialState = {
  data: {}
};

export const clienteReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.CLIENTE_UPDATE_VALUE:
      return {
        ...state,
        data: action.data
      };
    default:
      return state;
  }
}