import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import usePageTracking from "../../hooks/usePageTracking";
import LoadingService from "../../services/LoadingService";
import PromocaoService, { Mechanic } from "../../services/PromocaoService";
import { PromotionItem } from "../../types/PromotionItem";
import PromocaoItemReducedPrice from "./PromocaoItemReducedPrice";
import LoginService from "../../services/LoginService";
import { useHistory } from "react-router";
import { useReactToPrint } from "react-to-print";
import ModeloImpressao from "./ModeloImpressao";
import CpfService from "../../services/CpfService";
import AlertService from "../../services/AlertService";
import sleep from "../../services/sleep";
import PromocaoItemSpendAndGet from "./PromocaoItemSpendAndGet";
import "./styles.scss";
import ButtonHnt from "../ButtonHnt";
import { orderBy } from "lodash";

const Promocoes = (props: any) => {
  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  usePageTracking();
  const history = useHistory();
  const { authState } = useSelector((state: any) => state);
  const [carregando, setCarregando] = useState(true);
  const [promocoesAguardandoAtivacao, setPromocoesAguardandoAtivacao] =
    useState<PromotionItem[]>([]);
  const [promocoesDisponiveis, setPromocoesDisponiveis] = useState<
    PromotionItem[]
  >([]);
  const [promocoesAtivas, setPromocoesAtivas] = useState<PromotionItem[]>([]);
  const [ramo2Width, setRamo2Width] = useState(0);

  useEffect(() => {
    let timeoutResize: any;

    function handleResize() {
      if (timeoutResize) {
        clearTimeout(timeoutResize);
      }
      timeoutResize = setTimeout(() => {
        let p = 22;
        if (window.innerWidth < 992) {
          p = 30;
        }
        if (window.innerWidth < 768) {
          p = 40;
        }
        setRamo2Width((window.innerWidth * p) / 100);
      }, 250);
    }

    window.addEventListener("resize", handleResize);

    handleResize();
  });

  const filterPromotions = (items: PromotionItem[]) => {
    return items;
  };

  const doLogout = () => {
    history.push("/login");
    LoginService.doLogout();
  };

  async function ativarTodasOfertas(
    aguardandoAtivacao: PromotionItem[],
    tentativa: number = 1
  ): Promise<any> {
    if (tentativa === 1) {
      LoadingService.show("Ativando as promoções...");
    }

    let promises: Promise<any>[] = [];

    for (let i = 0; i < aguardandoAtivacao.length; i++) {
      const item = aguardandoAtivacao[i];
      promises.push(
        new Promise(async (resolve, reject) => {
          const cpf = CpfService.formatar(
            item.customerId.substring(item.customerId.length - 11)
          );
          const res = await PromocaoService.ativarPromocao(
            cpf,
            item.promotionId
          );
          if (res) {
            return resolve(res);
          } else {
            return reject(res);
          }
        })
      );
    }

    try {
      await Promise.all(promises);
      //a api responde como se tivesse vinculado a promocao com sucesso, mas nao ativou
      //reportado para hnt via e-mail
      //recursividade ativa para tentar novamente até ativar todas ou alcançar um limite
      const lista = await getPromocoesAtivas();
      const aguardandoMesmoAposAtivacao = lista.filter(
        (item) => !item.activated
      );

      if (aguardandoMesmoAposAtivacao.length > 0) {
        await sleep(1000);
        tentativa++;
        if (tentativa > 10) {
          throw Error(
            "Não foi possível ativar as ofertas, máximo de tentativas alcançado."
          );
        }
        return ativarTodasOfertas(aguardandoMesmoAposAtivacao, tentativa);
      }
      AlertService.show("Ofertas ativadas com sucesso!");
      setPromocoesDisponiveis(lista);
    } catch (err: any) {
      console.error(err);
      AlertService.error(
        "Houve um erro ao ativar uma ou mais ofertas. Por favor, tente novamente."
      );
    }
  }

  function voltar() {
    history.goBack();
  }

  function irParaTopo() {
    window.scrollTo(0, 0);
  }

  useEffect(() => {
    const res = promocoesDisponiveis.filter(
      (item) => item.promotion.requiresActivation && !item.activated
    );
    setPromocoesAguardandoAtivacao(res);
  }, [promocoesDisponiveis]);

  useEffect(() => {
    const res = promocoesDisponiveis.filter(
      (item) =>
        !item.promotion.requiresActivation ||
        (item.promotion.requiresActivation && item.activated)
    );
    setPromocoesAtivas(res);
  }, [promocoesDisponiveis]);

  async function getPromocoesAtivas(): Promise<PromotionItem[]> {
    try {
      const res = await PromocaoService.getPromocoesByCpf(
        authState.data.Cpf,
        process.env.REACT_APP_MECHANIC as Mechanic
      );
      const ativas = filterPromotions(res.items);
      const ordered = orderPromotions(ativas);
      return ordered;
    } catch (e) {
      console.error(e);
      return [];
    }
  }

  async function carregarPromocoes(): Promise<void> {
    const ativas = await getPromocoesAtivas();
    setPromocoesDisponiveis(ativas);
  }

  function orderPromotions(items: PromotionItem[]): PromotionItem[] {
    const sortedList = orderBy(
      items,
      [
        (item) => (item.promotion.legalText?.startsWith("*") ? 0 : 1),
        "promotion.description",
      ],
      ["asc", "asc"]
    );
    return sortedList;
  }

  useEffect(() => {
    async function fetch() {
      setCarregando(true);
      LoadingService.show(
        "Estamos verificando se existe oferta personalizada quentinha para você..."
      );
      await carregarPromocoes();
      LoadingService.hide();
      setCarregando(false);
    }
    fetch();
  }, [authState.data.Cpf]);

  return (
    <div className="container-fluid my-3">
      <img src="assets/images/ramo-2.png" id="ramo2" width={ramo2Width} />
      {!carregando && (
        <>
          {promocoesDisponiveis.length > 0 ? (
            <>
              <ButtonHnt text="Voltar" icon="left" onClick={voltar} />
              <div className="d-lg-none">
                <div>
                  <img
                    src="/assets/images/banner-ofertas-exclusivas.png"
                    alt="Ofertas Personalizadas"
                    id="banner-ofertas-exclusivas"
                    style={{ maxWidth: "80%" }}
                  />
                </div>
                {promocoesAguardandoAtivacao.length > 0 && (
                  <div className="d-flex justify-content-center mb-3">
                    <button
                      className="btn btn-success m-2 px-4 py-3"
                      id="btn-ativar-todas"
                      type="button"
                      onClick={() =>
                        ativarTodasOfertas(promocoesAguardandoAtivacao)
                      }
                    >
                      ATIVAR TODAS
                      <br />
                      AS OFERTAS
                    </button>
                  </div>
                )}
              </div>
              <div className="d-none d-lg-block mb-5">
                <div id="banner-ofertas-exclusivas-lg-container">
                  <div
                    id="banner-ofertas-exclusivas-lg"
                    style={{
                      backgroundImage: "url(assets/images/enfeite-2.png)",
                    }}
                  >
                    <div className="d-flex">
                      <div
                        style={{
                          marginTop:
                            promocoesAguardandoAtivacao.length === 0 ? 60 : 0,
                        }}
                      >
                        <img
                          src="/assets/images/banner-ofertas-exclusivas-lg.png"
                          alt="Ofertas Personalizadas"
                          id="banner-ofertas-exclusivas"
                          style={{ maxWidth: "80%" }}
                        />
                      </div>
                      {promocoesAguardandoAtivacao.length > 0 && (
                        <div>
                          <div className="d-flex justify-content-center mb-3">
                            <button
                              className="btn btn-success m-2 px-4 py-3"
                              id="btn-ativar-todas"
                              type="button"
                              onClick={() =>
                                ativarTodasOfertas(promocoesAguardandoAtivacao)
                              }
                            >
                              ATIVAR TODAS
                              <br />
                              AS OFERTAS
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="d-flex flex-wrap justify-content-center m-n2"
                id="promocoes-disponiveis-container"
              >
                {promocoesDisponiveis.map((item) => {
                  if (item.promotion.mechanic === "reduced_price")
                    return (
                      <PromocaoItemReducedPrice
                        item={item}
                        key={item.promotionId}
                        carregarPromocoes={carregarPromocoes}
                      />
                    );
                  if (item.promotion.mechanic === "spend_and_get_percent")
                    return (
                      <PromocaoItemSpendAndGet
                        item={item}
                        key={item.promotionId}
                        carregarPromocoes={carregarPromocoes}
                      />
                    );
                })}
              </div>

              {promocoesAtivas.length > 0 && (
                <div style={{ display: "none" }}>
                  <ModeloImpressao
                    ref={componentRef}
                    promocoes={promocoesAtivas}
                  />
                </div>
              )}
            </>
          ) : (
            <p>
              Poxa, infelizmente não temos nenhuma promoção disponível no
              momento.
              <br />
              Caso não faça parte do nosso programa de relacionamento,{" "}
              <a
                href="https://sejalevenatural.com.br/v1/apps/main#cadastro"
                className="text-success"
                title="Clique para se cadastrar"
              >
                cadastre-se
              </a>{" "}
              e desfrute de todos os benefícios.
            </p>
          )}

          <div className="d-flex justify-content-between mt-4">
            <ButtonHnt text="Voltar" icon="left" onClick={voltar} />
            {promocoesDisponiveis.length > 0 && (
              <ButtonHnt
                text="Voltar para o topo"
                icon="up"
                onClick={irParaTopo}
              />
            )}
            {promocoesAtivas.length > 0 && (
              <ButtonHnt text="Imprimir" icon="smile" onClick={handlePrint} />
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default Promocoes;
