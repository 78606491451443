import { round } from 'lodash';
import { PromotionItem } from '../../types/PromotionItem';
import NumberFormat from 'react-number-format';
import './PromocaoItem.scss';
import { useEffect, useState } from 'react';
import VitrineCarousel from '../VitrineCarousel';
import { Button } from 'reactstrap';
import PromocaoService from '../../services/PromocaoService';
import CpfService from '../../services/CpfService';
import moment from 'moment';
import AlertService from '../../services/AlertService';
import LoadingService from '../../services/LoadingService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbsUp } from '@fortawesome/free-solid-svg-icons';

const PromocaoItemReducedPrice = (props: PromocaoItemProps) => {

    const { item } = props;

    const [imagens, setImagens] = useState<string[]>([]);

    const getPercentualDesconto = (original: number, final: number): number => {
        const res = (100 * final / original) - 100;
        return round(res, 2);
    }

    const ativarPromocao = async (item: PromotionItem) => {
        LoadingService.show('Ativando a promoção...');
        const cpf = CpfService.formatar(item.customerId.substring(item.customerId.length - 11));

        const res = await PromocaoService.ativarPromocao(cpf, item.promotionId);

        LoadingService.hide();

        if (res) {
            await props.carregarPromocoes();
            AlertService.show('Promoção ativada com sucesso!<br><a href="https://sejalevenatural.com.br/v1/apps/main#cadastro" class="text-success"  target="_blank" title="Clique para atualizar o seu cadastro">Atualizar cadastro</a>');
        }
    }

    useEffect(() => {
        let itens: string[] = [];

        [item.promotion.urlImage1, item.promotion.urlImage2, item.promotion.urlImage3, item.promotion.urlImage4, item.promotion.urlImage5]
            .forEach(urlImage => urlImage ? itens.push(urlImage) : null);

        setImagens(itens);

    }, [item.promotion])


    return (
        <div className="card promocao-item m-2">
            <VitrineCarousel images={imagens} />
            <div className='percentualDesconto bg-danger'>
                <NumberFormat value={getPercentualDesconto(item.promotion.originalPrice, item.promotion.finalPrice)} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} fixedDecimalScale={true} decimalScale={2} suffix={'%'} />
            </div>
            <div className="card-body d-flex flex-column justify-content-between">
                <div>
                    <h5 className="card-title">{item.promotion.description}</h5>
                    <p className="card-text">
                        {item.promotion.legalText}
                        <span className="precoInfoContainer my-2">
                            <span className="precoOriginal text-danger">de  <NumberFormat value={item.promotion.originalPrice} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} fixedDecimalScale={true} decimalScale={2} prefix={'R$'} /></span><br />
                            <span className="precoFinal text-success">por <NumberFormat value={item.promotion.finalPrice} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} fixedDecimalScale={true} decimalScale={2} prefix={'R$'} /></span>
                        </span>
                        <span>Válido até <b>{moment(item.promotion.endDate).format('DD/MM')}</b></span>
                    </p>
                </div>
                {item.promotion.requiresActivation && !item.activated ?
                    <Button color='primary' className='mt-2' block={true} onClick={() => ativarPromocao(item)}>Ativar Promoção</Button>
                    : 
                    <Button type='button' className='btn btn-secondary mt-2 btn-block' ><FontAwesomeIcon icon={faThumbsUp} /> Promoção já ativada</Button>
                }
            </div>
        </div >
    )
}

interface PromocaoItemProps {
    item: PromotionItem,
    carregarPromocoes: CallableFunction
}

export default PromocaoItemReducedPrice;