class StorageService {
  static backupStorage: any = {};
  static storageMode = "localStorage";

  protected static resolveStorage(): void {
    let test;
    try {
      test = window.localStorage.length >= 0;
    } catch (e) {
      try {
        test = window.sessionStorage.length >= 0;
        this.storageMode = "sessionStorage";
      } catch (e) {
        this.storageMode = "backupStorage";
      }
    }
  }

  static async setItem(key: string, value: string): Promise<void> {
    this.resolveStorage();
    switch (this.storageMode) {
      case "localStorage":
        await localStorage.setItem(key, value);
        break;
      case "sessionStorage":
        await sessionStorage.setItem(key, value);
        break;
      case "backupStorage":
        this.backupStorage[key] = value;
        break;
    }
  }

  static async getItem(key: string): Promise<string | undefined> {
    this.resolveStorage();
    let value = undefined;
    switch (this.storageMode) {
      case "localStorage":
        value = await localStorage.getItem(key);
        break;
      case "sessionStorage":
        value = await sessionStorage.getItem(key);
        break;
      case "backupStorage":
        value = this.backupStorage[key];
        break;
    }

    return value;
  }

  static async removeItem(key: string): Promise<void> {
    this.resolveStorage();
    switch (this.storageMode) {
      case "localStorage":
        await localStorage.removeItem(key);
        break;
      case "sessionStorage":
        await sessionStorage.removeItem(key);
        break;
      case "backupStorage":
        delete this.backupStorage[key];
        break;
    }
  }
}

export default StorageService;
