import React, { useEffect } from "react";
import Routes from "./routes";
import { useLocation } from "react-router-dom";
import ReactPixel from "react-facebook-pixel";

const App = () => {
  let location = useLocation();

  React.useEffect(() => {
    ReactPixel.pageView();
  }, [location]);

  useEffect(() => {
    if (
      window.location.href.includes("cronoex.com.br") &&
      window.location.protocol === "http:"
    ) {
      window.location.href = window.location.href.replace("http:/", "https:/");
    }
  });

  return <Routes />;
};

export default App;
