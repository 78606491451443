import Auth from '../types/Auth';
import Cliente from '../types/Cliente';
import * as ACTIONS from './actionTypes';

export const clienteUpdate = (value: Cliente) => ({
  type: ACTIONS.CLIENTE_UPDATE_VALUE,
  data: value
});

export const authUpdate = (value?: Auth) => ({
  type: ACTIONS.AUTH_UPDATE,
  data: value
});

export const userLogout = () => ({
  type: ACTIONS.USER_LOGOUT,
});

