import { useEffect, useState} from "react";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga4";
import { useSelector } from "react-redux";

const usePageTracking = () => {
  const location = useLocation();
  const { authState } = useSelector((state: any) => state);

  function startTracker(){
    ReactGA.reset();

    ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS ?? '', {
      gaOptions:{
        user_id: authState.data.Cpf,
      }
    });
  }

  useEffect(() => {
    const pageUri = location.pathname + location.search;
    ReactGA.send({ hitType: "pageview", page: pageUri,  title: pageUri });
  }, [location]);

  useEffect(()=>{
    startTracker();
  }, [authState.data.Cpf]);

  
};

export default usePageTracking;